import {ErrorObject} from "@vuelidate/core"
import {Ref} from "vue"

export function useFieldError(property: string, inputErrors: ErrorObject[]): string | Ref<string> | null {
    const errors = [...inputErrors]
    /** Cannot check also if error array is empty, because else it does not work with the term validation */
    if (!Array.isArray(errors)) return null
    const error = errors.filter(item => {
        return item.$property === property
    })
    if (error.length) {
        return error[0].$message
    }
    return null
}
