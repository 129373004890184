<template>
	<div>
		<CourseDate
				v-for="value in radioValues"
				:id="value.id"
				:key="value.id"
				:courseEvent="courseEvent"
				:eventdate="value"
				:modelValue="selected[value.id]"
				:name="uuid"
				@update:modelValue="updateRadio(value.id)"
				@use-proposed-dates="emit('UseProposedDates', $event)"
		>
			<div class="flex justify-between">
				<div>{{ value.title }}</div>
				<div>
					<PriceAlert :value="value" class="text-right"></PriceAlert>
				</div>
			</div>
			<template v-slot:comment>
				{{ value.comment }}
			</template>
		</CourseDate>
	</div>
</template>

<script lang="ts" setup>
import PriceAlert from "../../../ui/PriceAlert.vue"
import CourseDate from "./CourseDate.vue"
import {nextTick, onMounted, reactive} from "vue"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {CartEventInterface} from "@/js/interfaces/event"

const props = defineProps<{
	courseEvent: CartEventInterface
	radioValues: EventdateInterface[]
	modelValue?: number,
}>()
const emit = defineEmits(["UseProposedDates", "update:modelValue"])

const uuid = Math.random()

const selected = reactive({})

const emitSelected = () => {
	for (let key in selected) {
		if (selected[key] === true) {
			emit("update:modelValue", parseInt(key))
		}
	}
}

const updateRadio = (value) => {
	for (let key in selected) {
		if (parseInt(key) === parseInt(value)) {
			selected[key] = true
		} else {
			selected[key] = false
		}
	}
	emitSelected()
}
const initGroup = () => {
	let c = 0
	props.radioValues.forEach((value) => {
		c += 1
		if (c === 1 && !props.modelValue) {
			selected[value.id] = true
		} else if (props.modelValue) {
			selected[value.id] = true
		} else {
			selected[value.id] = false
		}
	})
	nextTick(() => {
		emitSelected()
	})
}

onMounted(() => {
	initGroup()
})
</script>
