<template>
	<fieldset>
		<div class="text-primary text-md bold uppercase mb-2" data-testid="eventdates-option-title">{{ title }}</div>
		<div class="-space-y-px">
			<RadioGroup v-model="selected" :radio-values="eventdates" data-testid="eventdate" required></RadioGroup>
		</div>
	</fieldset>
</template>

<script lang="ts" setup>
import RadioGroup from "../../ui/RadioGroup.vue"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {computed} from "vue"

const props = defineProps<{
	modelValue?: number,
	eventdates: EventdateInterface[]
}>()

const emit = defineEmits(["update:modelValue"])

const title = computed(() => {
	return props.eventdates[0].option_title
})

const selected = computed({
	get: () => {
		return props.modelValue ? props.modelValue : null
	},
	set: (value) => {
		emit("update:modelValue", value)
	}
})
</script>
