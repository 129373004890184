<template>
	<div data-testid="radio-group">
		<template v-if="!required">
			<Radio
					:id="0"
					:name="uuid"
					:model-value="hasSelected() === false"
					data-testid="radio"
					@update:model-value="updateRadio(null)"
			>
				<div class="flex justify-between">
					<div>Keine Auswahl</div>
					<div>
					</div>
				</div>
			</Radio>
		</template>
		<Radio
				v-for="value in radioValues"
				:id="value.id"
				:key="value.id"
				:name="uuid"
				:model-value="selected[value.id]"
				data-testid="radio"
				@update:model-value="updateRadio(value.id)"
		>
			<div class="flex justify-between">
				<div>{{ value.title }}</div>
				<div>
					<PriceAlert :value="value" class="text-right"></PriceAlert>
				</div>
			</div>
			<template v-slot:comment>
				{{ value.comment }}
			</template>
		</Radio>
	</div>
</template>

<script lang="ts" setup>
import Radio from "./Radio.vue"
import PriceAlert from "./PriceAlert.vue"
import {nextTick, onMounted, reactive} from "vue"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {EventOptionValueInterfacePlusComment} from "@/js/interfaces/option"

const props = withDefaults(defineProps<{
	radioValues: EventdateInterface[] | EventOptionValueInterfacePlusComment[]
	modelValue?: number | null
	required?: boolean
}>(), {
	required: false,
})

const emit = defineEmits(["update:modelValue"])

const uuid = Math.random()

const selected = reactive({})

const hasSelected = () => {
	for (let key in selected) {
		if (selected[key] === true) {
			return true
		}
	}
	return false
}

const updateRadio = (value) => {
	if (value === null) {
		for (let key in selected) {
			selected[key] = false
		}
		emitSelected()
		return
	}
	for (let key in selected) {
		selected[key] = parseInt(key) === parseInt(value)
	}
	emitSelected()
}

const emitSelected = () => {
	for (let key in selected) {
		if (selected[key] === true) {
			emit("update:modelValue", parseInt(key))
		}
	}
}
const initGroup = () => {
	let c = 0
	props.radioValues.forEach((value) => {
		c += 1
		if (c === 1 && !props.modelValue && props.required) {
			selected[value.id] = true
		} else if (!props.required) {
			selected[value.id] = false
		} else {
			selected[value.id] = !!(props.modelValue && props.modelValue === value.id)
		}
	})
	nextTick(() => {
		emitSelected()
	})
}

onMounted(() => {
	initGroup()
})
</script>

